<template>
  <form @submit.prevent="submit">
    <h3 class="form-section-title">General information</h3>
    <div class="form-group">
      <div class="logo-input-container">
        <v-img
          class="logo-preview mx-5"
          :aspect-ratio="0.9"
          :max-height="125"
          :src="displayLogo"
          contain
          v-if="displayLogo"
        >
        </v-img>
        <div class="logo-preview-placeholder" v-else>
          <p>Logo will show here.</p>
          <p class="logo-preview-note">Only PNG, JPG and JPEG are accepted.</p>
        </div>
        <div class="mt-2 ma-auto">
          <upload-file-button
            v-model="orgLogo"
            @change="setPreviewLogo"
            title="Upload image"
            width="150"
          />
          <delete-file-button
            :resource-type="orgInfo.label"
            :resource-id="orgInfo.id"
            :fileUrl="orgInfo.logo_url"
            v-if="orgInfo.id && orgInfo.avatar !== ''"
            width="150"
            @delete="onDeleteLogo"
          />
        </div>
      </div>
      <div class="mt-2">
        <v-text-field
          label="Name"
          v-model.trim="orgName"
          class="form-item"
          :error-messages="inputError.name"
        />
        <v-text-field
          label="Website"
          :value="orgWebsite"
          class="form-item"
          readonly
          persistent-hint
        />
        <v-checkbox
          aria-label="website prefix checkbox"
          v-model="hasWebsitePrefix"
          class="form-item website-prefix-checkbox"
          color="inqliPurple"
          dense
        >
          <template v-slot:label>
            <span class="website-prefix-checkbox-label"
              >Add <code>www</code> to website</span
            >
          </template>
        </v-checkbox>
        <v-select
          class="form-item"
          label="Type"
          :items="orgTypeList"
          v-model="orgType"
        />
      </div>
    </div>

    <v-textarea
      class="form-item mt-3"
      label="Description"
      v-model.trim="orgDescription"
      dense
      rows="4"
      counter
      :error-messages="inputError.description"
    />
    <h3 class="form-section-title">Location</h3>
    <location-input
      class="form-group"
      v-model="location"
      :input-error="inputError.country"
    />
    <div class="form-buttons">
      <outline-button width="40%" @click="goToDashboard">
        Cancel
      </outline-button>
      <inqli-button width="40%" type="submit" :isProcess="isSubmitting"
        >Submit</inqli-button
      >
    </div>
  </form>
</template>
<script>
import { required, url } from "vuelidate/lib/validators";
import InqliButton from "../../app/components/buttons/Button.vue";
import OutlineButton from "../../app/components/buttons/OutlineButton.vue";
import sendRequest from "../../app/utils/send-request-helper";
import { isValidImageFormat } from "../../app/utils/validation-helper";
import uploadImage from "../../app/utils/upload-image-helper";
import LocationInput from "../../app/components/inputs/LocationInput.vue";
import UploadFileButton from "../../app/components/media/UploadFileButton.vue";
import DeleteFileButton from "../../app/components/media/DeleteFileButton.vue";

export default {
  name: "OrgForm",
  components: {
    "inqli-button": InqliButton,
    "outline-button": OutlineButton,
    "location-input": LocationInput,
    "upload-file-button": UploadFileButton,
    "delete-file-button": DeleteFileButton,
  },
  props: {
    orgInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      inputError: {
        name: "",
        country: "",
        description: "",
        logo: "",
      },
      hasWebsitePrefix: this.orgInfo.website_url.includes("www"),
      orgName: this.orgInfo.name,
      orgWebsite: this.orgInfo.website_url,
      orgType: this.orgInfo.type,
      orgDescription: this.orgInfo.description,
      orgLogo: null,
      location: {
        country: this.orgInfo.location.country,
        state: this.orgInfo.location.state,
        city: this.orgInfo.location.city,
      },
      isSubmitting: false,
      previewLogo: null,
    };
  },
  validations: {
    orgName: { required },
    orgDescription: { required },
    orgLogo: {
      isValidLogo(file) {
        if (file) {
          return isValidImageFormat(file);
        }
        return true; // logo can be null
      },
    },
    location: {
      country: { required },
    },
  },
  computed: {
    displayLogo() {
      return this.previewLogo != null
        ? this.previewLogo
        : this.orgInfo.logo_url;
    },
    orgTypeList() {
      return ["company", "post-secondary", "highschool", "non-profit"];
    },
    orgNameError() {
      const errors = [];
      if (this.$v.orgName.$invalid && !this.$v.orgName.required) {
        errors.push("Organization name is required");
      }
      return errors;
    },
  },
  watch: {
    hasWebsitePrefix(val) {
      if (val) {
        this.orgWebsite = this.orgWebsite.replace("https://", "https://www.");
      } else {
        this.orgWebsite = this.orgWebsite.replace("www.", "");
      }
    },
    orgName() {
      this.validateOrgName();
    },
    orgDescription() {
      this.validateOrgDescription();
    },
    orgLogo() {
      this.validateOrgLogo();
    },
    location() {
      this.orgState = "";
      this.orgCity = "";
      this.validateOrgCountry();
    },
    orgState() {
      this.orgCity = "";
    },
  },
  beforeDestroy() {
    this.clearData();
  },
  methods: {
    onDeleteLogo() {
      this.orgLogo = null;
      this.previewLogo = "";
      this.editOrg({
        logo_url: "",
      });
    },
    validateOrgName() {
      if (this.$v.orgName.$invalid && !this.$v.orgName.required) {
        this.inputError.name = "Organization name is required.";
      } else {
        this.inputError.name = "";
      }
    },
    validateOrgLogo() {
      if (this.$v.orgLogo.$invalid && !this.$v.orgLogo.isValidLogo) {
        this.inputError.logo = "Invalid logo format";
      } else {
        this.inputError.logo = "";
      }
    },
    validateOrgDescription() {
      if (this.$v.orgDescription.$invalid && !this.$v.orgDescription.required) {
        this.inputError.description = "Organization description is required.";
      } else {
        this.inputError.description = "";
      }
    },
    validateOrgCountry() {
      if (this.$v.location.$invalid && !this.$v.location.required) {
        this.inputError.country = "Country is required.";
      } else {
        this.inputError.country = "";
      }
    },
    validateImageFormat(file) {
      return isValidImageFormat(file);
    },
    setPreviewLogo(file) {
      try {
        if (file && this.validateImageFormat(file)) {
          var reader = new FileReader();
          reader.onload = (e) => {
            this.image = new Image();
            this.previewLogo = e.target.result;
          };
          reader.readAsDataURL(file);
        } else {
          this.previewLogo = null;
        }
      } catch (error) {
        this.$store.dispatch(
          "callAlert",
          error.message || "Can not upload image."
        );
      }
    },
    async submit() {
      if (this.isSubmitting) return;
      this.isSubmitting = true;
      this.resetInputError();
      const isInputValid = this.validate();
      if (isInputValid) {
        // upload logo
        let imageUrl = this.orgInfo.logo_url;
        if (this.orgLogo) {
          imageUrl = await uploadImage(this.orgLogo, {
            fileUrl: imageUrl,
            resourceId: this.orgInfo.id,
            resourceType: this.orgInfo.label,
          });
        }
        const data = {
          name: this.orgName,
          website_url: this.orgWebsite,
          type: this.orgType,
          logo_url: imageUrl,
          description: this.orgDescription,
          location: this.location,
        };
        await this.editOrg(data);
        this.goToDashboard();
      }
      this.isSubmitting = false;
    },
    async editOrg(orgDetails) {
      try {
        await sendRequest({
          url: `/resources/organizations/${this.orgInfo.id}`,
          method: "PUT",
          body: orgDetails,
          isAuth: true,
        });
      } catch (err) {
        this.$store.dispatch("callAlert", err.message);
      }
    },
    resetInputError() {
      this.inputError = {
        name: "",
        website: "",
        country: "",
        description: "",
      };
    },
    validate() {
      if (this.$v.$invalid) {
        this.validateOrgName();
        this.validateOrgLogo();
        this.validateOrgDescription();
        this.validateOrgCountry();
        return false;
      }
      return true;
    },
    goToDashboard() {
      this.$router.push("/organizations/" + this.orgInfo.id);
    },
    // get domain from a link (a link may not be a valid url)
    getDomain(link) {
      return url(link) ? new URL(link).hostname : link;
    },
    clearData() {
      this.org = null;
      this.inputError = null;
      this.previewLogo = null;
    },
  },
};
</script>
<style lang="scss" scoped>
.form-buttons {
  display: flex;
  justify-content: space-between;
  margin: 0 20px;
  margin-top: 30px;
  margin-bottom: 10px;
}
.form-group {
  display: flex;
  justify-content: space-between;
}
.form-item {
  margin: 0 20px;
}
.logo-input-container {
  width: 42%;
  margin-right: 20px;
}
.form-section-title {
  margin-top: 15px;
  margin-bottom: 10px;
}
.logo-preview-placeholder {
  width: 150px;
  height: 135px;
  margin: 0 auto;
  background-color: $inqliIconGray;
  border: 2px dashed $inqliGray;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  vertical-align: center;
  flex-direction: column;
  justify-content: center;
  p {
    font-weight: bold;
    color: $inqliDarkBlue;
  }
  .logo-preview-note {
    font-size: 11px;
    font-style: italic;
    margin-top: -10px;
    font-weight: normal;
  }
}
.website-prefix-checkbox {
  margin-top: -20px;
  .website-prefix-checkbox-label {
    font-size: 13px;
  }
}
@media (max-width: $breakpoint-mobile) {
  .form-group {
    display: block;
  }
  .logo-input-container {
    width: 90%;
    margin: auto;
  }
}
</style>
