<template>
  <layout :showTopBanner="false">
    <app-header />
    <v-card class="container" rounded="xl">
      <h1>Edit organization profile</h1>

      <div class="mt-4">
        <organization-form :orgInfo="org" v-if="!isLoadingOrgInfo" />
      </div>
    </v-card>
  </layout>
</template>
<script>
import Layout from "../app/components/layouts/DefaultLayout.vue";
import Header from "../app/components/headers/DefaultHeader";
import OrganizationForm from "./components/OrgEditForm.vue";
import { checkUserAsOrgAdmin } from "./utils/org-member-request";
import { getOrg } from "./utils/org-request";
export default {
  name: "EditOrg",
  components: {
    layout: Layout,
    "app-header": Header,
    "organization-form": OrganizationForm,
  },
  metaInfo: {
    title: "Edit organization profile",
  },
  data() {
    return {
      org: {},
      isLoadingOrgInfo: true,
    };
  },
  async mounted() {
    const orgId = this.$route.params.orgId;
    try {
      const isSystemAdmin = this.$store.state.user.isSystemAdmin;
      if (!isSystemAdmin) {
        const isOrgAdmin = await checkUserAsOrgAdmin(
          this.$store.state.user.username,
          orgId
        );
        if (!isOrgAdmin) {
          // go back to org page
          this.$router.push(`/organizations/${orgId}`);
        }
      }

      // get org info
      let org = await getOrg(orgId);
      this.org = org;
    } catch (err) {
      this.$store.dispatch(
        "callAlert",
        "An error occurred while sending requests."
      );
    }
    this.isLoadingOrgInfo = false;
  },
};
</script>

<style lang="scss" scoped>
.container {
  max-width: 700px;
  width: 70%;
  flex: 1;
  margin: 0 auto;
  margin-top: 30px;
  padding: 30px 64px;
  .top-title {
    color: $inqliGreen;
    font-size: 18px !important;
  }
}
@media screen and (max-width: $breakpoint-mobile) {
  .container {
    width: 95%;
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 30px;
  }
}
</style>
