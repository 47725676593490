import sendRequest from "../../app/utils/send-request-helper";

export const checkUserAsOrgAdmin = async (userId, orgId) => {
  try {
    const response = await sendRequest({
      url: `/relationships/member?source_id=${userId}&dest_id=${orgId}`,
      isAuth: true,
      method: "GET",
    });
    return response && response.is_admin;
  } catch (error) {
    return false;
  }
};
export const getUserRelationshipWithOrg = async (userId, orgId) => {
  const response = await sendRequest({
    url: `/relationships/member?source_id=${userId}&dest_id=${orgId}`,
    isAuth: true,
    method: "GET",
  });
  return response;
};

export const editMemberRelationship = async (userId, orgId, body) => {
  const response = await sendRequest({
    url: `/relationships/member?source_id=${userId}&dest_id=${orgId}`,
    isAuth: true,
    method: "PUT",
    body,
  });
  return response;
};

export const removeMemberRelationship = async (userId, orgId) => {
  const response = await sendRequest({
    url: `/relationships/member?source_id=${userId}&dest_id=${orgId}`,
    isAuth: true,
    method: "DELETE",
  });
  return response;
};

export const getMemberList = async (orgId, { limit, page, include }) => {
  const response = await sendRequest({
    url: `/relationships/member?dest_id=${orgId}&limit=${limit}&page=${page}&include=${include}`,
    isAuth: true,
    method: "GET",
  });
  return response;
};
