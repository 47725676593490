var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.submit.apply(null, arguments)
        }
      }
    },
    [
      _c("h3", { staticClass: "form-section-title" }, [
        _vm._v("General information")
      ]),
      _c("div", { staticClass: "form-group" }, [
        _c(
          "div",
          { staticClass: "logo-input-container" },
          [
            _vm.displayLogo
              ? _c("v-img", {
                  staticClass: "logo-preview mx-5",
                  attrs: {
                    "aspect-ratio": 0.9,
                    "max-height": 125,
                    src: _vm.displayLogo,
                    contain: ""
                  }
                })
              : _c("div", { staticClass: "logo-preview-placeholder" }, [
                  _c("p", [_vm._v("Logo will show here.")]),
                  _c("p", { staticClass: "logo-preview-note" }, [
                    _vm._v("Only PNG, JPG and JPEG are accepted.")
                  ])
                ]),
            _c(
              "div",
              { staticClass: "mt-2 ma-auto" },
              [
                _c("upload-file-button", {
                  attrs: { title: "Upload image", width: "150" },
                  on: { change: _vm.setPreviewLogo },
                  model: {
                    value: _vm.orgLogo,
                    callback: function($$v) {
                      _vm.orgLogo = $$v
                    },
                    expression: "orgLogo"
                  }
                }),
                _vm.orgInfo.id && _vm.orgInfo.avatar !== ""
                  ? _c("delete-file-button", {
                      attrs: {
                        "resource-type": _vm.orgInfo.label,
                        "resource-id": _vm.orgInfo.id,
                        fileUrl: _vm.orgInfo.logo_url,
                        width: "150"
                      },
                      on: { delete: _vm.onDeleteLogo }
                    })
                  : _vm._e()
              ],
              1
            )
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mt-2" },
          [
            _c("v-text-field", {
              staticClass: "form-item",
              attrs: { label: "Name", "error-messages": _vm.inputError.name },
              model: {
                value: _vm.orgName,
                callback: function($$v) {
                  _vm.orgName = typeof $$v === "string" ? $$v.trim() : $$v
                },
                expression: "orgName"
              }
            }),
            _c("v-text-field", {
              staticClass: "form-item",
              attrs: {
                label: "Website",
                value: _vm.orgWebsite,
                readonly: "",
                "persistent-hint": ""
              }
            }),
            _c("v-checkbox", {
              staticClass: "form-item website-prefix-checkbox",
              attrs: {
                "aria-label": "website prefix checkbox",
                color: "inqliPurple",
                dense: ""
              },
              scopedSlots: _vm._u([
                {
                  key: "label",
                  fn: function() {
                    return [
                      _c(
                        "span",
                        { staticClass: "website-prefix-checkbox-label" },
                        [
                          _vm._v("Add "),
                          _c("code", [_vm._v("www")]),
                          _vm._v(" to website")
                        ]
                      )
                    ]
                  },
                  proxy: true
                }
              ]),
              model: {
                value: _vm.hasWebsitePrefix,
                callback: function($$v) {
                  _vm.hasWebsitePrefix = $$v
                },
                expression: "hasWebsitePrefix"
              }
            }),
            _c("v-select", {
              staticClass: "form-item",
              attrs: { label: "Type", items: _vm.orgTypeList },
              model: {
                value: _vm.orgType,
                callback: function($$v) {
                  _vm.orgType = $$v
                },
                expression: "orgType"
              }
            })
          ],
          1
        )
      ]),
      _c("v-textarea", {
        staticClass: "form-item mt-3",
        attrs: {
          label: "Description",
          dense: "",
          rows: "4",
          counter: "",
          "error-messages": _vm.inputError.description
        },
        model: {
          value: _vm.orgDescription,
          callback: function($$v) {
            _vm.orgDescription = typeof $$v === "string" ? $$v.trim() : $$v
          },
          expression: "orgDescription"
        }
      }),
      _c("h3", { staticClass: "form-section-title" }, [_vm._v("Location")]),
      _c("location-input", {
        staticClass: "form-group",
        attrs: { "input-error": _vm.inputError.country },
        model: {
          value: _vm.location,
          callback: function($$v) {
            _vm.location = $$v
          },
          expression: "location"
        }
      }),
      _c(
        "div",
        { staticClass: "form-buttons" },
        [
          _c(
            "outline-button",
            { attrs: { width: "40%" }, on: { click: _vm.goToDashboard } },
            [_vm._v(" Cancel ")]
          ),
          _c(
            "inqli-button",
            {
              attrs: {
                width: "40%",
                type: "submit",
                isProcess: _vm.isSubmitting
              }
            },
            [_vm._v("Submit")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }