var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout",
    { attrs: { showTopBanner: false } },
    [
      _c("app-header"),
      _c("v-card", { staticClass: "container", attrs: { rounded: "xl" } }, [
        _c("h1", [_vm._v("Edit organization profile")]),
        _c(
          "div",
          { staticClass: "mt-4" },
          [
            !_vm.isLoadingOrgInfo
              ? _c("organization-form", { attrs: { orgInfo: _vm.org } })
              : _vm._e()
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }