import sendRequest from "../../app/utils/send-request-helper";

/**
 * Get org information by a given org id
 * @param orgId
 * @param include - a list of extra info, eg: members
 */
export const getOrg = async (orgId, include) => {
  let requestUrl = `/resources/organizations/${orgId}`;
  requestUrl += include ? `?include=${include}` : "";

  const orgResponse = await sendRequest({
    url: requestUrl,
    method: "GET",
    isAuth: true,
  });
  return orgResponse;
};
